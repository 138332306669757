
import "./stats.css";
import { Col, Row } from 'antd';

const Statistics = () => {
    return (
        <>
            <div className="row">
                <Row>
                    <Col style={{background : "#E9F8FC", width : "26%"}} className="m-4">
                        <p className="m-0">xTimesR USD</p>
                        <p className="m-0">Funds to invest:</p>
                        <p style={{fontWeight:"bolder"}} className="m-0">$50,258.50</p>
                    </Col>
                    <Col className="m-4" style={{ width : "26%"}}>
                        <p className="m-0">Demo USD</p>
                        <p className="m-0">Funds to invest:</p>
                        <p style={{fontWeight:"bolder"}} className="m-0">$50,258.50</p>
                    </Col>
                    <Col className="m-4" style={{ width : "26%"}}>
                        <p style={{fontWeight:"bolder", padding : "12px"}} className="m-0"><i class="bi bi-plus-lg"></i> Add new Account</p>
                    </Col>
                    
                </Row>
            </div>
        </>
    )
}

export default Statistics;