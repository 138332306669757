
import Dashboard from "../dashboard/index"


const Item = (props) => {
  const { page } = props;
  if (page === "Home") {
    return <div id="page"><Dashboard/></div> ;
  } else {
    return <div id="page">{page}</div>;
  }
};

export default Item;
