

const TopHeader = () =>{
    return (
        <>
        <div style={{width : "98%", lineHeight:"10px"}} className="mt-4 row">
            <div className="d-lg-block d-md-block  d-none  mb-3 col col-sm-9 col-md-6">
                <div className="input-group ">
                    <span className="input-group-text"><i class="bi bi-search"></i></span>
                    <input type="text" className="form-control" aria-label="Dollar amount (with dot and two decimal places)"/>
                </div>
            </div>
            
            <div className=" mb-3 col col-sm-9 col-md-3 ">
                <div style={{
                    backgroundColor:"#FFE39B", 
                    height:"100%",
                    borderRadius : "5.26px", 
                    opacity : "75%", 
                    padding : "11px",
                    fontWeight : "bold",
                    fontSize: "15px",
                    maxWidth : "150px"
                    }}>Refresh & Earn</div>
            </div>
            <div className=" mb-3 col-sm-2 col-md-3">
                <div style={{
                    fontSize: "15px",
                    padding : "11px",
                    fontWeight : "bold",
                    height : "100%",
                    }}>
                    <span style={{
                        backgroundColor: "#C9C9C9",  
                        borderRadius: "4.25358px",
                        backgroundColor: "#C9C9C9", 
                        lineHeight: "100%"
                        
                        }}>KZ</span>
                    <span className="	d-none d-sm-block" style={{marginLeft: "4px"}}>Kudzie Zvowere</span> 
                </div>
            </div>
        </div>
        </>
    )
}

export default TopHeader;