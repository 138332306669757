
import TopHeader from "../dashboard/topBar";
import Statistics from "../dashboard/statistics";
import InvestNow from "../dashboard/investNow";
import Charts from "../dashboard/charts";

const Dashboard = () => {
    return (
        <div style={{width:"98%"}} className="page">
            <TopHeader/>
            <Statistics/>
            <InvestNow/>
            <Charts/>
        </div>
    )
}

export default Dashboard;