import React from 'react';
import ReactECharts from 'echarts-for-react';

export default class Series extends React.Component {
	render(){
		const options = {
			grid: { top: 8, right: 8, bottom: 24, left: 36 },
			xAxis: {
			  type: 'category',
			  data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			},
			yAxis: {
			  type: 'value',
			},
			series: [
			  {
				data: [2,3,4,4,5,4,4],
				type: 'line',
				smooth: true,
			  },{
				data: [2,3,4,4,5,4,4],
				type: 'line',
				smooth: true,
			  }
			],
			tooltip: {
			  trigger: 'axis',
			},
		  };
		  return <>
		  		<h5 style={{fontWeight:"bold"}}> FUNDS</h5>
				<div>
					<i className="row m-4 mb-3">
						<div className="bg-success col-md-1" style={{display:"block", height:"5px", width:"5px"}}></div><div style={{height:"5px", width:"5px"}} className="col">Confirmed</div> <div  style={{display:"block", height:"5px", width:"5px"}}  className="bg-primary col-md-1"></div><div  style={{height:"5px", width:"5px"}}  className="col">Tota Shifts</div>
					</i></div>
			  	<ReactECharts option={options} />
		  	</>;
	}
}
