import {
  HomeIcon,
  LayoutIcon,
  CalendarIcon,
  InvoiceIcon,
  UserIcon,
  RolesIcon
} from "./Icons";

export const SIDEBAR_DATA = [
  {
    id: 1,
    name: "Home",
    path: "Home",
    icon: <HomeIcon />,
  },
  {
    id: 2,
    name: "Invest Now",
    path: "invest",
    icon: <LayoutIcon />,
  },
  {
    id: 3,
    name: "New Listings",
    path: "listing",
    icon: <CalendarIcon />,
  },
  {
    id: 4,
    name: "Sell",
    path: "sell",
    icon: <InvoiceIcon />,
  },
  {
    id: 5,
    name: "Recurring Investments",
    path: "recurringInvestments",
    icon: <UserIcon />,
  },
  {
    id: 6,
    name: "Pending Orders",
    path: "pendingOrders",
    icon: <RolesIcon />,
  }
];
