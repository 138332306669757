import { Col, Row } from 'antd';
import Gragh from "./graph"

const Charts = () => {
    return (
        <>
            <Row>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
                <Col style={{width: "25%"}}>
                    <Gragh/>
                </Col>
            </Row>
        </>
    )
}

export default Charts;